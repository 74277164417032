<template>
  <v-dialog
    :value="value"
    width="400"
    @keydown.esc="handleClose"
    @click:outside="handleClose"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        {{ format(date, "yyyy/MM/dd") }} 備註
        <v-spacer />
        <v-icon :disabled="isLoading" @click="handleClose"> mdi-close </v-icon>
      </v-toolbar>
      <v-card-text class="mt-8">
        <v-row>
          <v-col md="12">
            <div class="d-flex">
              <v-row dense>
                <v-col cols="12">
                  <v-textarea
                    v-model="_remark"
                    style="font-size: 14px"
                    label="備註欄"
                    outlined
                    hide-details
                    rows="4"
                    persistent-placeholder
                    :disabled="isLoading"
                  />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          class="primary white--text"
          :disabled="isLoading"
          :loading="isLoading"
          @click="handleSaveClicked"
        >
          {{ SHARED_LABELS.save }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { LABELS as SHARED_LABELS } from "@/shared/sharedLabels";
import { useDayRemarkStore } from "@/stores/dayRemark";
import { format } from "date-fns";
import { ref, watch } from "vue";

interface PropType {
  value: boolean;
  date: string;
  remark: string;
}

const props = defineProps<PropType>();
const emit = defineEmits(["close"]);
const dayRemarkStore = useDayRemarkStore();
const _remark = ref("");
const isLoading = ref(false);

watch(
  () => props.value,
  () => {
    reset();
  }
);

const reset = () => {
  _remark.value = props.remark;
};

const handleClose = () => {
  emit("close");
  reset();
};

const handleSaveClicked = async () => {
  if (isLoading.value) {
    return;
  }
  isLoading.value = true;
  try {
    await dayRemarkStore.createOrUpdateDayRemark(props.date, {
      remark: _remark.value.trim() || null,
    });
    handleClose();
  } finally {
    isLoading.value = false;
  }
};
</script>

import axios from "axios";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { REFERRAL_METADATA } from "@/shared/sharedConst";
import {
  AppointmentRequestDTO,
  AppointmentRequestEditInput,
  AppointmentRequestSource,
  AppointmentRequestStatus,
  AppointmentRequestType,
  ClinicSite,
  ReferralTheme,
  AppointmentRequestFilterInput,
} from "@/types";
import { DataOptions } from "vuetify";

export const useAppointmentRequestStore = defineStore(
  "appointmentRequest",
  () => {
    const dueDate = ref<string>();
    const customThemeKey = ref<ReferralTheme>();
    const taiwanId = ref<string>();
    const chineseName = ref<string>();
    const englishName = ref<string>();
    const primaryMobile = ref<string>();
    const secondaryMobile = ref<string>();
    const dob = ref<string>();
    const email = ref<string>();
    const address = ref<string>();
    const appointmentRequests = ref<Record<string, AppointmentRequestDTO>>({});
    const tableOptions = ref<DataOptions>({
      page: 0,
      itemsPerPage: 100,
      sortBy: ["remarks", "_id"],
      sortDesc: [true, false],
      groupBy: [],
      groupDesc: [],
      multiSort: true,
      mustSort: false,
    });

    const searchTerm = ref<string>();

    // Filters
    const hideStatusesFilter = ref<Set<AppointmentRequestStatus>>(
      new Set(["completed", "cancelled", "unable_to_contact_send_email"])
    );
    const hideSitesFilter = ref<Set<ClinicSite>>(new Set());
    const hideSourcesFilter = ref<Set<AppointmentRequestSource>>(new Set());
    const hideTypesFilter = ref<Set<AppointmentRequestType>>(new Set());

    // const getSortKey = (apptReq: AppointmentRequestDTO) =>
    //   `${apptReq.remarks ? "0" : "1"}${apptReq.updatedAt}`;

    const getAppointmentRequestById = computed(
      () => (appointmentRequestId: string) =>
        appointmentRequests.value[appointmentRequestId]
    );

    const fetchAppointmentRequests = async (
      input?: AppointmentRequestFilterInput
    ) => {
      const {
        data,
      }: { data: { items: AppointmentRequestDTO[]; totalCount: number } } =
        await axios.get("appointment-requests", {
          params: input,
        });
      setAppointmentRequests(data.items);
      return {
        itemIds: data.items.map((item) => item._id),
        totalCount: data.totalCount,
      };
    };

    const fetchAppointmentRequest = async (
      apptRequestId: string
    ): Promise<AppointmentRequestDTO> => {
      const { data }: { data: AppointmentRequestDTO } = await axios.get(
        `appointment-requests/${apptRequestId}`
      );
      return data;
    };

    const setAppointmentRequests = (
      apptRequests: AppointmentRequestDTO[]
    ): void => {
      const newAppointmentRequests: Record<string, AppointmentRequestDTO> = {};
      apptRequests.forEach((item) => {
        newAppointmentRequests[item._id] = item;
      });
      appointmentRequests.value = {
        ...appointmentRequests.value,
        ...newAppointmentRequests,
      };
    };

    const onWsNewAppointmentRequest =
      ref<(appointmentRequestId: string) => Promise<void>>();
    const handleWsNewAppointmentRequest = async (
      appointmentRequestId: string
    ) => {
      if (onWsNewAppointmentRequest.value) {
        return onWsNewAppointmentRequest.value(appointmentRequestId);
      }
    };

    const onWsUpdateAppointmentRequest =
      ref<(appointmentRequestId: string) => Promise<void>>();
    const handleWsUpdateAppointmentRequest = async (
      appointmentRequestId: string
    ) => {
      if (onWsUpdateAppointmentRequest.value) {
        return onWsUpdateAppointmentRequest.value(appointmentRequestId);
      }
    };

    const fetchAppointmentRequestFromOldReferral = async (
      key: string
    ): Promise<AppointmentRequestDTO> => {
      const { data } = await axios.get(
        `appointment-requests/old_referral/${key}`
      );
      return data;
    };

    const createAppointmentRequestFromReferralForm = async (arg: {
      type: AppointmentRequestType;
      source?: AppointmentRequestSource;
    }) => {
      const dto = {
        type: arg.type,
        dueDate: dueDate.value,
        taiwanId: taiwanId.value,
        chineseName: chineseName.value,
        englishName: englishName.value,
        primaryMobile: primaryMobile.value,
        secondaryMobile: secondaryMobile.value,
        customThemeKey: customThemeKey.value,
        customThemeText: customThemeKey.value
          ? REFERRAL_METADATA[customThemeKey.value].text
          : "",
        dob: dob.value,
        email: email.value,
        address: address.value,
        source: arg.source ?? "referral",
      };
      const { data }: { data: AppointmentRequestDTO } = await axios.post(
        `appointment-requests`,
        dto
      );
      setAppointmentRequests([data]);
    };

    // Referral form
    const displayedReferralList = computed<AppointmentRequestDTO[]>(() => {
      return [];
    });

    const clearReferralForm = () => {
      dueDate.value = undefined;
      taiwanId.value = undefined;
      chineseName.value = undefined;
      englishName.value = undefined;
      primaryMobile.value = undefined;
      secondaryMobile.value = undefined;
      dob.value = undefined;
      email.value = undefined;
      address.value = undefined;
    };

    // Staff form
    const staffUpdate = async (
      appointmentRequestId: string,
      body: AppointmentRequestEditInput
    ) => {
      const { data } = await axios.patch<AppointmentRequestDTO>(
        `appointment-requests/${appointmentRequestId}`,
        body
      );
      setAppointmentRequests([data]);
    };

    return {
      getAppointmentRequestById,
      searchTerm,
      appointmentRequests,
      fetchAppointmentRequests,
      fetchAppointmentRequest,
      handleWsNewAppointmentRequest,
      handleWsUpdateAppointmentRequest,
      setAppointmentRequests,
      createAppointmentRequestFromReferralForm,
      fetchAppointmentRequestFromOldReferral,
      hideStatusesFilter,
      hideSitesFilter,
      hideSourcesFilter,
      hideTypesFilter,
      // Staff form
      staffUpdate,
      // Referral form
      displayedReferralList,
      dueDate,
      customThemeKey,
      taiwanId,
      chineseName,
      englishName,
      primaryMobile,
      secondaryMobile,
      dob,
      email,
      address,
      clearReferralForm,
      tableOptions,
      onWsUpdateAppointmentRequest,
      onWsNewAppointmentRequest,
    };
  }
);

import { DeleteTransactionPayload } from "@/shared/sharedTypes";
import {
  TransactionDTO,
  TransactionEditInput,
  TransactionInput,
} from "@/types";
import axios from "axios";
import { defineStore } from "pinia";
import Vue from "vue";
import { useAppointmentGroupStore } from "./appointmentGroup";

interface State {
  transactions: Record<string, TransactionDTO | undefined>;
}

export const useTransactionStore = defineStore("transaction", {
  state: (): State => ({
    transactions: {},
  }),
  actions: {
    async fetchTransactionsByAppointmentGroupId(appointmentGroupId: string) {
      const { data }: { data: TransactionDTO[] } = await axios.get(
        `appointment-groups/${appointmentGroupId}/transactions`
      );
      this.setTransactions(data);
    },
    async fetchTransaction(transactionId: string) {
      const { data }: { data: TransactionDTO } = await axios.get(
        `transactions/${transactionId}`
      );
      this.setTransactions([data]);
    },
    async createTransaction(transaction: TransactionInput): Promise<void> {
      const { data }: { data: TransactionDTO } = await axios.post(
        `transactions`,
        transaction
      );
      this.setTransactions([data]);
    },
    async updateTransaction(
      transactionId: string,
      transaction: TransactionEditInput
    ): Promise<void> {
      const { data }: { data: TransactionDTO } = await axios.put(
        `transactions/${transactionId}`,
        transaction
      );
      this.setTransactions([data]);
    },
    async deleteTransaction(payload: DeleteTransactionPayload): Promise<void> {
      const { appointmentGroupId, transactionId } = payload;
      await axios.delete(`transactions/${transactionId}`);
      Vue.delete(this.transactions, transactionId);
      const appointmentGroupStore = useAppointmentGroupStore();
      appointmentGroupStore.fetchAppointmentGroup(appointmentGroupId, true);
    },
    setTransactions(transactions: TransactionDTO[]): void {
      transactions.forEach((transaction) => {
        this.transactions = {
          ...this.transactions,
          [transaction._id]: transaction,
        };
      });
    },
    handleWsCreate(id: string) {
      if (this.transactions[id]) {
        this.fetchTransaction(id);
      }
    },
  },
  getters: {
    getTransactionsByAppointmentGroupId: (state) => {
      return (appointmentGroupId: string) =>
        Object.values(state.transactions).filter(
          (transaction) =>
            transaction?.appointmentGroupId === appointmentGroupId
        );
    },
    getTransactionsByBankTransactionId: (state) => {
      return (bankTransactionId: string) =>
        Object.values(state.transactions).filter(
          (transaction) => transaction?.bankTransactionId === bankTransactionId
        );
    },
    getRegularTransactionsByAppointmentGroupId: (state) => {
      return (appointmentGroupId: string) =>
        Object.values(state.transactions).filter(
          (transaction) =>
            transaction?.appointmentGroupId === appointmentGroupId &&
            transaction.type === "regular"
        );
    },
    getAdjustmentTransactionsByAppointmentGroupId: (state) => {
      return (appointmentGroupId: string) =>
        Object.values(state.transactions).filter(
          (transaction) =>
            transaction?.appointmentGroupId === appointmentGroupId &&
            transaction.type !== "regular"
        );
    },
  },
});

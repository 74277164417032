import Vue from "vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { onAuthStateChanged } from "firebase/auth";
import * as firebase from "./plugins/firebase";
import { useUiStore } from "./stores/ui";
import axios from "axios";
import { debouncePlugin } from "./stores/plugins/debounce";

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(debouncePlugin);

const app = new Vue({
  router,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount("#app");

// Firebase
onAuthStateChanged(firebase.auth, async (user) => {
  useUiStore().setLoggedInUser(user || undefined);
  if (user) {
    if (app.$route.name === "login") {
      app.$router.push({ name: "appointment" });
    }
  } else {
    if (
      app.$route.name &&
      !app.$route.meta?.bypassAuth &&
      app.$route.name !== "login"
    ) {
      window.location.pathname = "/login";
    }
  }
});

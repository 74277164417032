import { TimeSlotDTO, TimeSlotInput } from "@/types";
import { TimeSlot, UpdateTimeSlotPayload } from "@/shared/sharedTypes";
import axios from "axios";
import { defineStore } from "pinia";
import Vue from "vue";
import { useAppointmentStore } from "./appointment";
import { axiosPost, axiosPut } from "@/utils/axios";

interface State {
  timeSlots: Record<string, TimeSlot | undefined>;
  dateLoadedMap: Record<string, boolean>;
}

export const useTimeSlotStore = defineStore("timeSlot", {
  state: (): State => ({
    timeSlots: {},
    dateLoadedMap: {},
  }),
  actions: {
    async fetchTimeSlotsForDate(date: string) {
      if (this.dateLoadedMap[date] === false) {
        return;
      }
      if (this.dateLoadedMap[date] === undefined) {
        this.dateLoadedMap = {
          ...this.dateLoadedMap,
          [date]: false,
        };
      }
      const { data }: { data: TimeSlotDTO[] } = await axios.get(
        `time-slots?date=${date}`
      );
      this.setTimeSlots(data);
      if (this.dateLoadedMap[date] === false) {
        this.dateLoadedMap = {
          ...this.dateLoadedMap,
          [date]: true,
        };
      }
      return [];
    },
    async fetchTimeSlot(id: string) {
      const { data }: { data: TimeSlotDTO } = await axios.get(
        `time-slots/${id}`
      );
      this.setTimeSlots([data]);
      return data;
    },
    async createTimeSlots(timeSlots: TimeSlotInput[]): Promise<void> {
      try {
        const data = await axiosPost<TimeSlotDTO[]>("time-slots", timeSlots);
        this.setTimeSlots(data);
      } catch (e: any) {
        if (e?.message) {
          window.alert(e?.message);
        }
      }
    },
    async updateTimeSlot(payload: UpdateTimeSlotPayload): Promise<void> {
      try {
        const { timeSlotId, capacity } = payload;
        const data = await axiosPut<TimeSlotDTO>(`time-slots/${timeSlotId}`, {
          capacity,
        });
        this.setTimeSlots([data]);
      } catch (e: any) {
        if (e?.message) {
          window.alert(e?.message);
        }
      }
    },
    async removeTimeSlot(id: string): Promise<void> {
      await axios.delete(`time-slots/${id}`);
      Vue.delete(this.timeSlots, id);
    },
    setTimeSlots(timeSlots: TimeSlotDTO[]): void {
      const newTimeSlots: { [timeSlotId: string]: TimeSlot } = {};
      if (Object.keys(timeSlots)) {
        timeSlots.forEach((timeSlot) => {
          if (timeSlot.isDeleted) {
            Vue.delete(this.timeSlots, timeSlot._id);
          } else {
            const { appointments, ...restOfTimeSlot } = timeSlot;
            newTimeSlots[timeSlot._id] = restOfTimeSlot;
            if (appointments) {
              const appointmentStore = useAppointmentStore();
              appointmentStore.setAppointments(appointments);
            }
          }
        });
        this.timeSlots = { ...this.timeSlots, ...newTimeSlots };
      }
    },
    handleWsCreate(date: string) {
      if (this.dateLoadedMap[date]) {
        this.fetchTimeSlotsForDate(date);
      }
    },
    handleWsDelete(id: string) {
      Vue.delete(this.timeSlots, id);
    },
  },
  getters: {
    timeSlotStartTimeAndSiteMap: (state): Record<string, boolean> => {
      const startTimeMap: Record<string, boolean> = {};
      Object.keys(state.timeSlots).forEach((timeSlot) => {
        const val = state.timeSlots[timeSlot];
        if (val && val.start && val.site) {
          startTimeMap[`${val.doctorId}_${val.start}_${val.site}`] = true;
        }
      });
      return startTimeMap;
    },
    getTimeSlotsByDate: (state) => {
      return (date: string) => {
        return Object.keys(state.timeSlots).reduce((timeSlots, id) => {
          const timeSlot = state.timeSlots[id];
          if (timeSlot && date === timeSlot.date) {
            timeSlots.push(timeSlot);
          }
          return timeSlots;
        }, [] as TimeSlot[]);
      };
    },
  },
  debounce: {
    fetchTimeSlotsForDate: 300,
  },
});

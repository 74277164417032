<template>
  <v-calendar
    weekdays="1,2,3,4,5,6"
    type="custom-weekly"
    :start="appointmentCalendarStore.date"
    :end="endDate"
    @click:day="handleDateClick"
    @click:date="handleDateClick"
  >
    <template #day-label="{ date }">
      <span class="text-subtitle-1">{{ moment(date).format("M/D") }}</span>
      <span class="text-subtitle-2 text--grey grey--text">
        {{
          pregnantWeek(date, dueDate) ? `(${pregnantWeek(date, dueDate)})` : ""
        }}</span
      >
    </template>
    <template #day="{ date }">
      <AppointmentCalendarAvailability :date="date" />
    </template>
  </v-calendar>
</template>

<script setup lang="ts">
import { CalendarType } from "@/shared/sharedEnums";
import { computed } from "vue";
import { pregnantWeek } from "@/utils/utils";
import { useAppointmentCalendarStore } from "@/stores/appointmentCalendar";
import { storeToRefs } from "pinia";
import moment from "moment";
import AppointmentCalendarAvailability from "../appointmentCalendarAvailability/appointmentCalendarAvailability.vue";

const appointmentCalendarStore = useAppointmentCalendarStore();
const { dueDate } = storeToRefs(appointmentCalendarStore);

const handleDateClick = (event: { date: string }): void => {
  appointmentCalendarStore.setCalendarType(CalendarType.CATEGORY);
  appointmentCalendarStore.setDate(event.date);
};

const endDate = computed(() => {
  return moment(appointmentCalendarStore.date)
    .add(3, "weeks")
    .subtract(1, "day")
    .format("YYYY-MM-DD");
});
</script>

<style>
.v-calendar-weekly__week {
  min-height: auto !important;
}
</style>

import pinyin from "pinyin";
import { PINYIN_TO_WG_MAP } from "./pinyinToWgMap";

const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;

export const formatTime = (date: Date): string => {
  const hour = date.getHours();
  const minute = date.getMinutes();
  return `${("0" + hour).slice(-2)}:${("0" + minute).slice(-2)}:00`;
};

export const dateDiffInDays = (a?: string, b?: string): number | undefined => {
  if (!a || !b) {
    return;
  }
  const date1 = new Date(a);
  const date2 = new Date(b);
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
  return Math.floor((utc2 - utc1) / MILLISECONDS_PER_DAY);
};

export const pregnantWeek = (a?: string, b?: string): string => {
  const diffDays = dateDiffInDays(a, b);
  if (diffDays === undefined) {
    return "";
  }
  const days = 40 * 7 - diffDays;
  return `${Math.floor(days / 7)}+${days % 7}`;
};

export const chineseNameToWgName = (name?: string): string | undefined => {
  if (!name) {
    return undefined;
  }
  const pinyinName = pinyin(name, { mode: "surname", style: "normal" });
  const pinyinLastName = pinyinName.slice(0, 1)[0][0];
  const pinyinFirstNames = pinyinName.slice(1).map((arr) => arr[0]);
  const wgFirstNames = pinyinFirstNames.map(
    (firstName) => PINYIN_TO_WG_MAP[firstName.toUpperCase()]
  );
  return `${
    PINYIN_TO_WG_MAP[pinyinLastName.toUpperCase()]
  }, ${wgFirstNames.join("-")}`;
};

export const setQueryStringParameters = (
  queryStringParameters: Record<string, string>
) => {
  // Get the current URL
  const url = new URL(window.location.href);
  const params = new URLSearchParams(); // url.search

  // Set a new value for a query parameter
  for (const [key, value] of Object.entries(queryStringParameters)) {
    params.set(key, value);
  }

  // Update the URL with the modified query parameters
  const newUrl = `${url.origin}${url.pathname}?${params.toString()}`;
  window.history.replaceState({}, "", newUrl);
};

export const getQueryStringParameters = () => {
  const url = new URL(window.location.href);
  const paramEntries = new URLSearchParams(url.search);
  const record: Record<string, string> = {};
  for (const [key, value] of paramEntries) {
    record[key] = value;
  }
  return record;
};

import { AppointmentLocationType } from "@/types";

export const LOCATION_TYPE_TO_ICON: Record<AppointmentLocationType, string> = {
  in_person: "mdi-hospital-building",
  virtual: "mdi-remote-desktop",
};

export const calendarIntervalMinutes = 30;
export const calendarFirstTime = "08:00";
export const calendarTimeList = [
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
];

/** 假如這些時間沒有開格子，約診那一頁就不會顯示這些時間，記得好像是Mabel姐要求的，因為全部顯示頁面太長 */
export const calendarAdditionalTimeList = [
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
];

export const calendarIntervalCount =
  calendarTimeList.length + calendarAdditionalTimeList.length;

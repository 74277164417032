import { ReceiptDTO, ReceiptFilterInput } from "@/types";
import axios from "axios";
import { defineStore } from "pinia";

interface State {
  receipts: Record<string, ReceiptDTO | undefined>;
  receiptsFetchedForAppointmentGroupIds: Set<string>;
}

export const useReceiptStore = defineStore("receipt", {
  state: (): State => ({
    receipts: {},
    receiptsFetchedForAppointmentGroupIds: new Set<string>(),
  }),
  actions: {
    async fetchReceipt(receiptId: string, force = false) {
      if (!force && this.receipts[receiptId]) {
        return;
      }
      const { data }: { data: ReceiptDTO } = await axios.get(
        `receipts/${receiptId}`
      );
      this.setReceipts([data]);
      return data;
    },
    async fetchReceiptsForAppointmentGroup(
      appointmentGroupId: string,
      force = false
    ) {
      if (
        !force &&
        this.receiptsFetchedForAppointmentGroupIds.has(appointmentGroupId)
      ) {
        return;
      }
      const { data }: { data: ReceiptDTO[] } = await axios.get(
        `appointment-groups/${appointmentGroupId}/receipts`
      );
      this.setReceipts(data);
      this.receiptsFetchedForAppointmentGroupIds.add(appointmentGroupId);
      return data;
    },
    async fetchReceipts(filterInput?: ReceiptFilterInput) {
      const { data } = await axios.get<{
        items: ReceiptDTO[];
        totalCount: number;
        sumOfTotal: number;
      }>(`receipts`, {
        params: filterInput,
      });
      this.setReceipts(data.items);
      return data;
    },
    setReceipts(receipts: ReceiptDTO[]): void {
      const newReceipts: {
        [receiptId: string]: ReceiptDTO;
      } = {};
      receipts.forEach((receipt) => {
        newReceipts[receipt._id] = receipt;
      });
      this.receipts = {
        ...this.receipts,
        ...newReceipts,
      };
    },
    handleWsCreate(id: string) {
      if (this.receipts[id]) {
        this.fetchReceipt(id);
      }
    },
  },
  getters: {
    getReceiptsForAppointmentGroup: (state) => {
      return (appointmentGroupId: string) => {
        const receipts: ReceiptDTO[] = [];
        Object.keys(state.receipts).forEach((id) => {
          const appointment = state.receipts[id];
          if (appointment?.appointmentGroupId === appointmentGroupId) {
            receipts.push(appointment);
          }
        });
        receipts.sort(
          (a, b) =>
            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
        );
        return receipts;
      };
    },
  },
});

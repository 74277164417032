import { AlertActionEnum, AlertItemTypeEnum } from "@/shared/sharedEnums";
import { User } from "firebase/auth";
import { defineStore } from "pinia";

interface State {
  isAuthInitialized: boolean;
  drawerVisible: boolean;
  currentUser?: User;
  alertDialog: boolean;
  alertStaffAccount: string | undefined;
  alertItemType: AlertItemTypeEnum | undefined;
  alertAction: AlertActionEnum | undefined;
  snackbarMessage: string | undefined;
}

export const useUiStore = defineStore("ui", {
  state: (): State => ({
    isAuthInitialized: false,
    drawerVisible: true,
    currentUser: undefined,
    alertDialog: false,
    alertStaffAccount: undefined,
    alertItemType: undefined,
    alertAction: undefined,
    snackbarMessage: undefined,
  }),
  getters: {
    isAuthenticated: (state) => !!state.currentUser,
  },
  actions: {
    toggleDrawerVisibility() {
      this.drawerVisible = !this.drawerVisible;
    },
    setDrawerVisible(visible: boolean) {
      this.drawerVisible = visible;
    },
    setLoggedInUser(user?: User) {
      this.currentUser = user;
      this.isAuthInitialized = true;
    },
    setAlertDialog(value: boolean) {
      this.alertDialog = value;
    },
    setAlertStaffAccount(value: string | undefined) {
      this.alertStaffAccount = value;
    },
    setAlertItemType(value: AlertItemTypeEnum | undefined) {
      this.alertItemType = value;
    },
    setAlertAction(value: AlertActionEnum | undefined) {
      this.alertAction = value;
    },
    setSnackbarMessage(snackbarMessage?: string) {
      this.snackbarMessage = snackbarMessage;
    },
  },
});

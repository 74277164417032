export const PINYIN_TO_WG_MAP: Record<string, string> = {
  A: "A",
  AI: "AI",
  AN: "AN",
  ANG: "ANG",
  AO: "AO",
  BA: "PA",
  BAI: "PAI",
  BAN: "PAN",
  BANG: "PANG",
  BAO: "PAO",
  BEI: "PEI",
  BEN: "PEN",
  BENG: "PENG",
  BI: "PI",
  BIAN: "PIEN",
  BIAO: "PIAO",
  BIE: "PIEH",
  BIN: "PIN",
  BING: "PING",
  BO: "PO",
  BU: "PU",
  CA: "TSA",
  CAI: "TSAI",
  CAN: "TSAN",
  CANG: "TSANG",
  CAO: "TSAO",
  CE: "TSE",
  CEN: "TSEN",
  CENG: "TSENG",
  CHA: "CHA",
  CHAI: "CHAI",
  CHAN: "CHAN",
  CHANG: "CHANG",
  CHAO: "CHAO",
  CHE: "CHE",
  CHEN: "CHEN",
  CHENG: "CHENG",
  CHI: "CHIH",
  CHONG: "CHUNG",
  CHOU: "CHOU",
  CHU: "CHU",
  CHUA: "CHUA",
  CHUAI: "CHUAI",
  CHUAN: "CHUAN",
  CHUANG: "CHUANG",
  CHUI: "CHUI",
  CHUN: "CHUN",
  CHUO: "CHO",
  CI: "TZU",
  CONG: "TSUNG",
  COU: "TSOU",
  CU: "TSU",
  CUAN: "TSUAN",
  CUI: "TSUI",
  CUN: "TSUN",
  CUO: "TSO",
  DA: "TA",
  DAI: "TAI",
  DAN: "TAN",
  DANG: "TANG",
  DAO: "TAO",
  DE: "TE",
  DEI: "TEI",
  DENG: "TENG",
  DI: "TI",
  DIAN: "TIEN",
  DIANG: "TIANG",
  DIAO: "TIAO",
  DIE: "TIEH",
  DING: "TING",
  DIU: "TIU",
  DONG: "TUNG",
  DOU: "TOU",
  DU: "TU",
  DUAN: "TUAN",
  DUI: "TUI",
  DUN: "TUN",
  DUO: "TO",
  E: "E",
  EI: "EI",
  EN: "EN",
  ENG: "ENG",
  ER: "ERH",
  FA: "FA",
  FAN: "FAN",
  FANG: "FANG",
  FEI: "FEI",
  FEN: "FEN",
  FENG: "FENG",
  FO: "FO",
  FOU: "FOU",
  FU: "FU",
  GA: "KA",
  GAI: "KAI",
  GAN: "KAN",
  GANG: "KANG",
  GAO: "KAO",
  GE: "KE",
  GEI: "KEI",
  GEN: "KEN",
  GENG: "KENG",
  GONG: "KUNG",
  GOU: "KOU",
  GU: "KU",
  GUA: "KUA",
  GUAI: "KUAI",
  GUAN: "KUAN",
  GUANG: "KUANG",
  GUI: "KUEI",
  GUN: "KUN",
  GUO: "KUO",
  HA: "HA",
  HAI: "HAI",
  HAN: "HAN",
  HANG: "HANG",
  HAO: "HAO",
  HE: "HE",
  HEI: "HEI",
  HEN: "HEN",
  HENG: "HENG",
  HONG: "HUNG",
  HOU: "HOU",
  HU: "HU",
  HUA: "HUA",
  HUAI: "HUAI",
  HUAN: "HUAN",
  HUANG: "HUANG",
  HUI: "HUI",
  HUN: "HUN",
  HUO: "HUO",
  JI: "CHI",
  JIA: "CHIA",
  JIAN: "CHIEN",
  JIANG: "CHIANG",
  JIAO: "CHIAO",
  JIE: "CHIEH",
  JIN: "CHIN",
  JING: "CHING",
  JIONG: "CHIUNG",
  JIU: "CHIU",
  JU: "CHU",
  JUAN: "CHUAN",
  JUE: "CHUEH",
  JUN: "CHUN",
  KA: "KA",
  KAI: "KAI",
  KAN: "KAN",
  KANG: "KANG",
  KAO: "KAO",
  KE: "KE",
  KEN: "KEN",
  KENG: "KENG",
  KONG: "KUNG",
  KOU: "KOU",
  KU: "KU",
  KUA: "KUA",
  KUAI: "KUAI",
  KUAN: "KUAN",
  KUANG: "KUANG",
  KUI: "KUEI",
  KUN: "KUN",
  KUO: "KUO",
  LA: "LA",
  LAI: "LAI",
  LAN: "LAN",
  LANG: "LANG",
  LAO: "LAO",
  LE: "LE",
  LEI: "LEI",
  LENG: "LENG",
  LI: "LI",
  LIA: "LIA",
  LIAN: "LIEN",
  LIANG: "LIANG",
  LIAO: "LIAO",
  LIE: "LIEH",
  LIN: "LIN",
  LING: "LING",
  LIU: "LIU",
  LO: "LO",
  LONG: "LUNG",
  LOU: "LOU",
  LU: "LU",
  LUAN: "LUAN",
  LUE: "LUEH",
  LUN: "LUN",
  LUO: "LO",
  LV: "LU",
  MA: "MA",
  MAI: "MAI",
  MAN: "MAN",
  MANG: "MANG",
  MAO: "MAO",
  ME: "ME",
  MEI: "MEI",
  MEN: "MEN",
  MENG: "MENG",
  MI: "MI",
  MIAN: "MIEN",
  MIAO: "MIAO",
  MIE: "MIEH",
  MIN: "MIN",
  MING: "MING",
  MIU: "MIU",
  MO: "MO",
  MOU: "MOU",
  MU: "MU",
  NA: "NA",
  NAI: "NAI",
  NAN: "NAN",
  NANG: "NANG",
  NAO: "NAO",
  NE: "NE",
  NEI: "NEI",
  NEN: "NEN",
  NENG: "NENG",
  NI: "NI",
  NIAN: "NIEN",
  NIANG: "NIANG",
  NIAO: "NIAO",
  NIE: "NIEH",
  NIN: "NIN",
  NING: "NING",
  NIU: "NIU",
  NONG: "NUNG",
  NOU: "NOU",
  NU: "NU",
  NUAN: "NUAN",
  NUE: "NUEH",
  NUO: "NO",
  O: "O",
  OU: "OU",
  PA: "PA",
  PAI: "PAI",
  PAN: "PAN",
  PANG: "PANG",
  PAO: "PAO",
  PEI: "PEI",
  PEN: "PEN",
  PENG: "PENG",
  PI: "PI",
  PIAN: "PIEN",
  PIAO: "PIAO",
  PIE: "PIEH",
  PIN: "PIN",
  PING: "PING",
  PO: "PO",
  POU: "POU",
  PU: "PU",
  QI: "CHI",
  QIA: "CHIA",
  QIAN: "CHIEN",
  QIANG: "CHIANG",
  QIAO: "CHIAO",
  QIE: "CHIEH",
  QIN: "CHIN",
  QING: "CHING",
  QIONG: "CHIUNG",
  QIU: "CHIU",
  QU: "CHU",
  QUAN: "CHUAN",
  QUE: "CHUEH",
  QUN: "CHUN",
  RAN: "JAN",
  RANG: "JANG",
  RAO: "JAO",
  RE: "JE",
  REN: "JEN",
  RENG: "JENG",
  RI: "JIH",
  RONG: "JUNG",
  ROU: "JOU",
  RU: "JU",
  RUAN: "JUAN",
  RUI: "JUI",
  RUN: "JUN",
  RUO: "JO",
  SA: "SA",
  SAI: "SAI",
  SAN: "SAN",
  SANG: "SANG",
  SAO: "SAO",
  SE: "SE",
  SEN: "SEN",
  SENG: "SENG",
  SHA: "SHA",
  SHAI: "SHAI",
  SHAN: "SHAN",
  SHANG: "SHANG",
  SHAO: "SHAO",
  SHE: "SHE",
  SHEI: "SHEI",
  SHEN: "SHEN",
  SHENG: "SHENG",
  SHI: "SHIH",
  SHOU: "SHOU",
  SHU: "SHU",
  SHUA: "SHUA",
  SHUAI: "SHUAI",
  SHUAN: "SHUAN",
  SHUANG: "SHUANG",
  SHUI: "SHUI",
  SHUN: "SHUN",
  SHUO: "SHUO",
  SI: "SSU",
  SONG: "SUNG",
  SOU: "SOU",
  SU: "SU",
  SUAN: "SUAN",
  SUI: "SUI",
  SUN: "SUN",
  SUO: "SO",
  TA: "TA",
  TAI: "TAI",
  TAN: "TAN",
  TANG: "TANG",
  TAO: "TAO",
  TE: "TE",
  TENG: "TENG",
  TI: "TI",
  TIAN: "TIEN",
  TIAO: "TIAO",
  TIE: "TIEH",
  TING: "TING",
  TONG: "TUNG",
  TOU: "TOU",
  TU: "TU",
  TUAN: "TUAN",
  TUI: "TUI",
  TUN: "TUN",
  TUO: "TO",
  WA: "WA",
  WAI: "WAI",
  WAN: "WAN",
  WANG: "WANG",
  WEI: "WEI",
  WEN: "WEN",
  WENG: "WENG",
  WO: "WO",
  WU: "WU",
  XI: "HSI",
  XIA: "HSIA",
  XIAN: "HSIEN",
  XIANG: "HSIANG",
  XIAO: "HSIAO",
  XIE: "HSIEH",
  XIN: "HSIN",
  XING: "HSING",
  XIONG: "HSIUNG",
  XIU: "HSIU",
  XU: "HSU",
  XUAN: "HSUAN",
  XUE: "HSUEH",
  XUN: "HSUN",
  YA: "YA",
  YAI: "YAI",
  YAN: "YEN",
  YANG: "YANG",
  YAO: "YAO",
  YE: "YEH",
  YI: "YI",
  YIN: "YIN",
  YING: "YING",
  YO: "YO",
  YONG: "YUNG",
  YOU: "YU",
  YU: "YU",
  YUAN: "YUAN",
  YUE: "YUEH",
  YUN: "YUN",
  ZA: "TSA",
  ZAI: "TSAI",
  ZAN: "TSAN",
  ZANG: "TSANG",
  ZAO: "TSAO",
  ZE: "TSE",
  ZEI: "TSEI",
  ZEN: "TSEN",
  ZENG: "TSENG",
  ZHA: "CHA",
  ZHAI: "CHAI",
  ZHAN: "CHAN",
  ZHANG: "CHANG",
  ZHAO: "CHAO",
  ZHE: "CHE",
  ZHEI: "CHEI",
  ZHEN: "CHEN",
  ZHENG: "CHENG",
  ZHI: "CHIH",
  ZHONG: "CHUNG",
  ZHOU: "CHOU",
  ZHU: "CHU",
  ZHUA: "CHUA",
  ZHUAI: "CHUAI",
  ZHUAN: "CHUAN",
  ZHUANG: "CHUANG",
  ZHUI: "CHUI",
  ZHUN: "CHUN",
  ZHUO: "CHO",
  ZI: "TZU",
  ZONG: "TSUNG",
  ZOU: "TSOU",
  ZU: "TSU",
  ZUAN: "TSUAN",
  ZUI: "TSUI",
  ZUN: "TSUN",
  ZUO: "TSO",
};

import { DebouncedFunc } from "lodash";
import debounce from "lodash/debounce";
import { PiniaPlugin } from "pinia";

export const debouncePlugin: PiniaPlugin = ({ options, store }) => {
  if (options.debounce) {
    return Object.keys(options.debounce).reduce((debouncedActions, action) => {
      debouncedActions[action] = debounce(
        store[action],
        options.debounce![action]
      );
      return debouncedActions;
    }, {} as Record<string, DebouncedFunc<any>>);
  }
};

import axios from "axios";
import { defineStore } from "pinia";

interface State {
  tenant: unknown | undefined;
  permissions: Record<string, boolean>;
}

export const useUserStore = defineStore("user", {
  state: (): State => ({
    tenant: undefined,
    permissions: {},
  }),
  actions: {
    async fetchUser() {
      const { data }: { data: { tenant: unknown; permissions: string[] } } =
        await axios.get(`users`);
      this.setPermissions(data.permissions);
      this.setTenant(data.tenant);
    },
    setPermissions(permissions: string[]): void {
      const permissionRecord: Record<string, boolean> = {};
      permissions.forEach((permissionKey) => {
        permissionRecord[permissionKey] = true;
      });
      this.permissions = permissionRecord;
    },
    setTenant(tenant: unknown): void {
      this.tenant = tenant;
    },
  },
});

import { StaffDTO } from "@/types";
import axios from "axios";
import { defineStore } from "pinia";

interface State {
  workingStaffByDate: Record<string, StaffDTO[] | undefined>;
  dateLoadedMap: Record<string, boolean>;
}

export const useStaffStore = defineStore("staff", {
  state: (): State => ({
    workingStaffByDate: {},
    dateLoadedMap: {},
  }),
  actions: {
    async fetchWorkingStaff(date: string) {
      if (this.dateLoadedMap[date] === false) {
        return;
      }
      if (this.dateLoadedMap[date] === undefined) {
        this.dateLoadedMap = {
          ...this.dateLoadedMap,
          [date]: false,
        };
      }
      const { data }: { data: StaffDTO[] } = await axios.get(
        `staff?date=${date}`
      );
      this.setStaffs(date, data);
      if (this.dateLoadedMap[date] === false) {
        this.dateLoadedMap = {
          ...this.dateLoadedMap,
          [date]: true,
        };
      }
    },
    setStaffs(date: string, staff: StaffDTO[]): void {
      this.workingStaffByDate = {
        ...this.workingStaffByDate,
        [date]: staff,
      };
    },
  },
});

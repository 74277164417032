import { ReferralTheme } from "@/types";
import { AppSelectItem } from "./sharedTypes";

export const REFERRAL_METADATA: {
  [key in ReferralTheme]: AppSelectItem<ReferralTheme>;
} = {
  down_syndrome_in_the_first_trimester: {
    text: "第一孕期唐氏症",
    value: "down_syndrome_in_the_first_trimester",
  },
  early_fetal_examination_nt_pigf_early_structure: {
    text: "早期胎檢（NT+PlGF+早期結構）",
    value: "early_fetal_examination_nt_pigf_early_structure",
  },
  high_level: {
    text: "高層次",
    value: "high_level",
  },
  integrated_fetal_inspection_early_fetal_inspection_high_level: {
    text: "整合式胎檢（早期胎檢+高層次）",
    value: "integrated_fetal_inspection_early_fetal_inspection_high_level",
  },
};

import { Doctor } from "@/types";
import axios from "axios";
import { defineStore } from "pinia";

interface State {
  doctors: Record<string, Doctor>;
  selectedDoctorId?: string;
  selectedDoctorIds: string[];
  isLoadingDoctors: boolean;
}

export const useDoctorStore = defineStore("doctor", {
  state: (): State => ({
    doctors: {},
    selectedDoctorId: undefined,
    selectedDoctorIds: [],
    isLoadingDoctors: false,
  }),
  actions: {
    async fetchDoctors() {
      if (Object.keys(this.doctors).length) {
        return;
      }
      if (this.isLoadingDoctors) return;
      try {
        this.isLoadingDoctors = true;
        const { data }: { data: Doctor[] } = await axios.get("doctors");
        data.forEach((doctor) => {
          this.doctors = {
            ...this.doctors,
            [doctor._id]: doctor,
          };
        });
        this.selectedDoctorIds = data.map((doctor) => doctor._id);
      } finally {
        this.isLoadingDoctors = false;
      }
    },
    setSelectedDoctorId(doctorId?: string) {
      this.selectedDoctorId = doctorId;
    },
    setSelectAllDoctor() {
      this.selectedDoctorIds = Object.keys(this.doctors);
    },
  },
  getters: {
    sortedDoctors(state) {
      const doctors = Object.values(state.doctors);
      return doctors.sort((a, b) => a.order - b.order);
    },
    doctorIdToOrder(state) {
      const doctors = Object.values(state.doctors);
      const idToOrder: Map<string, number> = new Map();
      doctors.forEach((doctor) => {
        idToOrder.set(doctor._id, doctor.order);
      });
      return idToOrder;
    },
  },
});

import {
  AppointmentRequestType,
  AppointmentGroupType,
  PaymentMethod,
  ALaCarteItem,
  PriceFactor,
  CheckType,
  ReferralSource,
  Period,
  ClinicSite,
  AppointmentRequestSource,
  AppointmentGroupCategory,
  PaidStatus,
  BloodTestLaboratory,
  TransactionType,
  ClinicName,
  AppointmentLocationType,
} from "@/types";

const PAYMENT_METHOD_LABELS: Record<PaymentMethod, string> = {
  cash: "現金",
  transfer: "匯款",
  cash_on_behalf_of: "代收款",
};

// 約診類型
const APPOINTMENT_REQUEST_TYPE_LABELS: Record<AppointmentRequestType, string> =
  {
    fetal: "產前胎檢",
    newborn: "新生兒篩檢",
    adult: "成人篩檢", // DOUBLE CHECK
  };

// 約診主題
const APPOINTMENT_GROUP_TYPE_LABELS: Record<AppointmentGroupType, string> = {
  early: "早期胎檢",
  first_trimester_fetal_screening: "早期結構篩檢",
  midterm: "中期胎檢",
  midterm_plus: "中期胎檢（PLUS）",
  late: "晚期胎檢",
  integrated: "整合式胎檢",
  dating: "妊娠週數確認",
  nt: "早期唐氏症篩檢",
  expert_heart: "專家門診（心）",
  expert_kidney: "專家門診（腎）",
  expert_cleft_lip: "專家門診（唇顎裂）",
  expert_brain: "專家門診（腦）",
  expert_genetics: "專家門診（遺傳諮詢）",
  expert_heart_counsultation: "專家門診（心臟大會診）",
  multidisciplinary: "多學科會診",
  follow_up: "追蹤門診",
  one_month: "滿月健檢（單購）",
  one_month_four_dot_zero: "滿月健檢（4.0）",
  one_month_five_dot_zero: "滿月健檢（5.0）",
  one_month_four_in_one: "滿月健檢（四合一）",
  one_month_five_in_one: "滿月健檢（五合一）",
  development_evaluation_bayley: "發展評估（Bayley）",
  development_evaluation_aims: "發展評估（AIMS）",
  development_evaluation_general: "發展評估（寶貝版）",
  new_born_follow_up: "追蹤門診",
  adult_heart: "成人心超",
};

// 單購
const A_LA_CARTE_ITEM_LABELS: Record<ALaCarteItem, string> = {
  pl_gf: "子癲前症篩檢",
  taipei_subsidy: "助您好孕",
  brain: "腦",
  heart: "心",
  kidney: "腎",
  abdomen: "腹",
  hip: "髖",
  ekg: "EKG",
  gms: "GMS",
  blood_test: "抽血費",
  torch: "TORCH",
};

// 約診註記
const PRICE_FACTOR_LABELS: Record<PriceFactor, string> = {
  discounted: "減免",
  regular: "原價",
  free: "不收費",
  taiji_patient: "台媽",
  non_taiji_patient: "非台媽",
};

// Check Types
const CHECK_TYPE_LABELS: Record<CheckType, string> = {
  dating: "妊娠週數確認",
  early_down_syndrome_screening: "早期唐氏症篩檢",
  early_down_syndrome_screening_with_plgf: "早期唐氏症篩檢＋子癲前症篩檢",
  first_trimester_fetal_screening: "早期結構篩檢",
  second_trimester_fetal_screening_18W: "中期結構篩檢（18W）",
  second_trimester_fetal_screening_22W: "中期結構篩檢（22W）",
  third_trimester_fetal_screening: "晚期結構篩檢",
  brain: "腦",
  heart: "心",
  abdomen: "腹",
  kidney: "腎",
  hip: "髖",
  cleft_lip: "唇顎裂",
  multidisciplinary: "多學科會診",
  follow_up: "追蹤門診",
  singular_parts: "單購",
  gms: "GMS",
  ekg: "EKG",
  bayley: "Bayley",
  aims: "AIMS",
  adult_heart: "成人心超",
  genetics: "遺傳諮詢",
  genetics_report: "遺傳諮詢報告解說",
  torch: "TORCH",
  heart_counsultation: "心臟共同會診",
  heart_consultation_explanation: "心臟諮詢解說",
  development_evaluation: "發展評估",
  development_evaluation_explanation: "評估報告解說",
};

// Clinic Site
const CLINIC_SITE_LABELS: Record<ClinicSite, string> = {
  new_taipei: "新北總部",
  taipei: "台北",
  chupei: "竹北",
  yuanlin: "員林",
  miaoli: "大千",
};

// Period
const PERIOD_LABELS: Record<Period, string> = {
  am: "上午(9:00~13:00)",
  pm: "下午(13:00~17:00)",
};

// 轉介醫院
const REFERRAL_SOURCE_LABELS: Record<ReferralSource, string> = {
  hsinsheng: "馨生",
  yuanlin_obxiao: "蕭弘智", // https://obxiao.com.tw/
  fm: "婦茂",
  hc_chujen: "竹仁",
  gu: "古芬蘭",
  yong: "永佳",
  ss: "杏萱",
  ccyeh: "葉長青",
  ems: "敏盛綜合",
  yoyo: "宥宥",
  tfcivf: "TFC臺北生殖",
  hyivf: "華育生殖",
  mldc: "大千醫院",
};

const APPOINTMENT_REQUEST_SOURCE: Record<AppointmentRequestSource, string> = {
  mamahealth: "APP",
  referral: "轉介",
};

const APPOINTMENT_GROUP_CATEGORY: Record<AppointmentGroupCategory, string> = {
  fetal: "產前",
  newborn: "新生兒",
  adult: "成人",
};

const PAID_STATUS: Record<PaidStatus, string> = {
  paid: "已付清",
  unpaid: "未付款",
  pending_refund: "待退款",
};

const BLOOD_TEST_LABORATORY_LABELS: Record<BloodTestLaboratory, string> = {
  taipei_lianhe: "台北聯合",
  hsinchu_lianhe: "新竹聯合",
  kaoshiung_lianxing: "高雄聯興",
};

const TRANSACTION_TYPE_LABELS: Record<TransactionType, string> = {
  regular: "收款（報到前）",
  adjustment: "調整（報到後）",
};

const CLINIC_NAME_LABELS: Record<ClinicName, string> = {
  taier_chupei_dongping: "台兒竹北東平診所",
  taier_yuanlin_renmei: "台兒員林仁美診所",
  taier_dachien: "台兒大千胎兒醫學科",
  wupeizhen: "吳佩臻診所",
  lindanwei: "林丹薇診所",
  zhouyuching: "周昱青診所",
  taipei_zhongshan: "台兒中山集英診所",
  taier: "台兒診所",
};

const LOCATION_TYPE_LABELS: Record<AppointmentLocationType, string> = {
  virtual: "遠距",
  in_person: "現場",
};

// 刪除約診套餐或項目的原因
const DELETE_REASONS: Array<string> = [
  "時間無法配合 / 臨時有事",
  "更改檢查項目",
  "胎兒異常 / 心跳停止",
  "更換檢查院所",
  "原產檢追蹤",
  "資料有問題",
];

export const LABELS = {
  paymentMethod: PAYMENT_METHOD_LABELS,
  appointmentRequestType: APPOINTMENT_REQUEST_TYPE_LABELS,
  appointmentGroupType: APPOINTMENT_GROUP_TYPE_LABELS,
  aLaCarteItem: A_LA_CARTE_ITEM_LABELS,
  priceFactor: PRICE_FACTOR_LABELS,
  checkType: CHECK_TYPE_LABELS,
  clinicSite: CLINIC_SITE_LABELS,
  period: PERIOD_LABELS,
  referralSource: REFERRAL_SOURCE_LABELS,
  appointmentRequestSource: APPOINTMENT_REQUEST_SOURCE,
  appointmentGroupCategory: APPOINTMENT_GROUP_CATEGORY,
  paidStatus: PAID_STATUS,
  bloodTestLaboratory: BLOOD_TEST_LABORATORY_LABELS,
  transactionType: TRANSACTION_TYPE_LABELS,
  clinicName: CLINIC_NAME_LABELS,
  locationType: LOCATION_TYPE_LABELS,
  deleteReasons: DELETE_REASONS,
  cancel: "取消",
  save: "儲存",
  add: "新增",
  edit: "編輯",
  doctor: "醫師",
  chineseName: "中文姓名",
  englishName: "英文姓名",
  taiwanId: "身分證",
  foreignerId: "居留證",
  patientId: "病歷號",
  email: "電子信箱",
  obstetrician: "產檢醫師",
  primaryMobile: "手機（孕婦）",
  secondaryMobile: "手機（家人）",
  address: "地址",
  fetusCount: "胎兒數",
  cooperativeHospital: "配合醫院",
  cooperativeHospitalMedicalHistoryNumber: "配合醫院病歷號碼",
  memo: "備註",
};

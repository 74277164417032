import { Overview } from "@/types";
import axios from "axios";
import { defineStore } from "pinia";

interface State {
  overviews: Record<string, Overview | undefined>;
}

const idSplitter = "::";

export const useOverviewStore = defineStore("overview", {
  state: (): State => ({
    overviews: {},
  }),
  getters: {
    overviewRecordIds(state: State): string[] {
      return Object.keys(state.overviews);
    },
    getOverviewsByDate: (state: State) => {
      return (date: string) => {
        const ids = Object.keys(state.overviews).filter((id) => {
          const [dateInId] = id.split(idSplitter);
          return dateInId === date;
        });
        return ids.map((id) => state.overviews[id]) as Overview[];
      };
    },
  },
  actions: {
    async fetchOverviews(start: string, end: string): Promise<void> {
      const url = `overviews?start=${start}&end=${end}`;
      const { data }: { data: Overview[] } = await axios.get(url);
      this.setOverviews({
        overviews: data,
      });
    },
    setOverviews({ overviews }: { overviews: Overview[] }): void {
      const newOverviews: {
        [date: string]: Overview;
      } = {};
      overviews.forEach((overview) => {
        const key = `${overview.date}${idSplitter}${overview.site}`;
        newOverviews[key] = overview;
      });
      this.overviews = { ...this.overviews, ...newOverviews };
    },
  },
  debounce: {
    fetchOverviews: 300,
  },
});

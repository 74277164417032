import moment from "moment";

export const getPregnantDays = (todayDate: string, dueDate?: string) => {
  if (!dueDate) return;
  const originDate = moment(dueDate).subtract(40, "weeks");
  const pregnantDays = moment(todayDate).diff(originDate, "days");
  return pregnantDays;
};

export const getPregnancyWeekNumber = (todayDate: string, dueDate?: string) => {
  const pregnantDays = getPregnantDays(todayDate, dueDate);
  if (!pregnantDays) return {};
  const weeks = Math.floor(pregnantDays / 7);
  const days = pregnantDays % 7;
  return { weeks, days };
};

export const getPregnancyWeek = (
  todayDate: string,
  dueDate?: string
): string => {
  const { weeks, days } = getPregnancyWeekNumber(todayDate, dueDate);
  if (weeks) {
    return `${weeks}W${days}D`;
  }
  return "";
};

export const getAge = (todayDate: string, dob?: string): string => {
  if (dob) {
    const todayDateInstance = moment(todayDate).add(1, "day"); // 假如今天出生的話，年齡是0Y0M1D
    const dobInstance = moment(dob);
    const diffDuration = moment.duration(todayDateInstance.diff(dobInstance));
    let ageString = "";
    const year = diffDuration.years();
    const month = diffDuration.months();
    const day = diffDuration.days();
    if (year) {
      ageString += `${year}Y`;
    }
    return `${ageString}${month}M${day}D`;
  }
  return "";
};

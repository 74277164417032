<template>
  <div>
    <v-toolbar flat dense class="mb-2">
      <v-btn-toggle
        v-if="supportedTypes.length > 1"
        v-model="calendarType"
        class="mr-2"
        dense
      >
        <v-btn
          v-for="supportedType in supportedTypes"
          :key="supportedType"
          :value="supportedType"
          class="white"
        >
          {{ LABELS[supportedType] }}
        </v-btn>
      </v-btn-toggle>
      <v-btn outlined class="mr-2" height="36" @click="handleTodayClick">
        {{ LABELS.today }}
      </v-btn>
      <template
        v-if="appointmentCalendarStore.calendarType === CalendarType.CATEGORY"
      >
        <v-btn outlined class="mr-2" height="36" @click="openOrderTablePage">
          {{ LABELS.orderTable }}
        </v-btn>
        <v-btn
          outlined
          class="mr-2"
          :class="{
            red: !!remark,
            'white--text': !!remark,
          }"
          height="36"
          @click="handleRemarksClick"
        >
          備註
        </v-btn>
        <v-btn outlined class="mr-2" height="36" @click="handleStaffClick">
          人力表
        </v-btn>
      </template>
      <DayRemarkDialog
        v-if="appointmentCalendarStore.date"
        v-model="showRemarksDialog"
        :remark="remark"
        :date="appointmentCalendarStore.date"
        @close="showRemarksDialog = false"
      />
      <StaffDialog
        v-if="appointmentCalendarStore.date"
        v-model="showStaffDialog"
        :date="appointmentCalendarStore.date"
        @close="showStaffDialog = false"
      />
      <v-btn-toggle
        v-if="
          [CalendarType.MONTH, CalendarType.CUSTOM_WEEK].includes(
            appointmentCalendarStore.calendarType
          )
        "
        v-model="selectedViewMode"
        dense
      >
        <v-btn value="show_available" outlined> 顯示可約 </v-btn>
        <v-btn value="show_all" outlined> 顯示所有 </v-btn>
      </v-btn-toggle>
      <v-btn fab text medium color="grey darken-2" @click="handlePrevClick">
        <v-icon medium>mdi-chevron-left</v-icon>
      </v-btn>
      <div class="d-flex align-center">
        <h3>{{ dateHeader }}</h3>
        <v-menu
          v-if="
            [CalendarType.CATEGORY, CalendarType.SCHEDULE].includes(
              appointmentCalendarStore.calendarType
            )
          "
          v-model="showCurrentDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="appointmentCalendarStore.date"
              class="ml-2 font-weight-bold"
              dense
              style="width: 100px; font-size: 18px"
              hide-details
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-if="appointmentCalendarStore.date"
            :value="appointmentCalendarStore.date"
            @change="handleDateChange"
            @input="showCurrentDatePicker = false"
          ></v-date-picker>
        </v-menu>
      </div>
      <v-btn fab text medium color="grey darken-2" @click="handleNextClick">
        <v-icon medium>mdi-chevron-right</v-icon>
      </v-btn>
      <v-spacer />
      <v-menu
        v-if="
          [
            CalendarType.CATEGORY,
            CalendarType.MONTH,
            CalendarType.CUSTOM_WEEK,
          ].includes(appointmentCalendarStore.calendarType)
        "
        v-model="showDueDatePicker"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        left
        min-width="auto"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            :value="dueDate"
            :label="LABELS.dueDate"
            hide-details
            type="date"
            max="2999-12-31"
            outlined
            dense
            style="min-width: 115px; max-width: 115px"
            v-bind="attrs"
            v-on="on"
            @change="handleDueDateChanged"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dueDate"
          @input="showDueDatePicker = false"
        ></v-date-picker>
      </v-menu>
      <span
        v-if="calendarType !== CalendarType.SCHEDULE && dueDate"
        class="ml-2"
      >
        <v-btn
          v-for="week in availableWeeks"
          :key="week"
          class="mr-1"
          outlined
          height="39"
          small
          @click="handleWeekClick(week)"
        >
          {{ week }}
        </v-btn>
      </span>
    </v-toolbar>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { CalendarType } from "@/shared/sharedEnums";
import { useDoctorStore } from "@/stores/doctor";
import { pregnantWeek } from "@/utils/utils";
import { computed, onMounted, ref, watch } from "vue";
import moment from "moment-timezone";
import { LABELS } from "./calendarLabels";
import { useAppointmentCalendarStore } from "@/stores/appointmentCalendar";
import { storeToRefs } from "pinia";
import { useAppointmentStore } from "@/stores/appointment";
import {
  Appointment,
  MonthlyCalendarViewMode,
  OrderTableRow,
  TimeSlot,
} from "@/shared/sharedTypes";
import {
  generateOrderTableLabelSuffix,
  generateOrderTableLabelPrefix,
} from "@/utils/generateFollowUpLabel";
import DayRemarkDialog from "../dayRemarkDialog/dayRemarkDialog.vue";
import StaffDialog from "../staffDialog/staffDialog.vue";
import { useDayRemarkStore } from "@/stores/dayRemark";
import { useRoute } from "vue-router/composables";
import { useUserStore } from "@/stores/user";
import { format } from "date-fns";
import { TZDate } from "@date-fns/tz";

interface PropType {
  supportedTypes: CalendarType[];
}

const props = defineProps<PropType>();
const emit = defineEmits(["prevClicked", "nextClicked", "weekSelected"]);
const showCurrentDatePicker = ref(false);
const showDueDatePicker = ref(false);
const availableWeeks = [11, 14, 18, 22, 28];
const showRemarksDialog = ref(false);
const showStaffDialog = ref(false);

const userStore = useUserStore();
const { tenant } = storeToRefs(userStore);
const doctorStore = useDoctorStore();
const dayRemarkStore = useDayRemarkStore();
const appointmentCalendarStore = useAppointmentCalendarStore();
const { dueDate } = storeToRefs(appointmentCalendarStore);
const route = useRoute();
const appointmentStore = useAppointmentStore();

const calendarType = computed({
  get() {
    return appointmentCalendarStore.calendarType;
  },
  set(type: CalendarType) {
    appointmentCalendarStore.setCalendarType(type);
  },
});

const remark = computed(() => {
  if (appointmentCalendarStore.date) {
    return (
      dayRemarkStore.dayRemarks[appointmentCalendarStore.date]?.remark || ""
    );
  }
  return "";
});

const selectedViewMode = computed({
  get(): MonthlyCalendarViewMode {
    return appointmentCalendarStore.monthlyCalendarViewMode;
  },
  set(viewMode: MonthlyCalendarViewMode): void {
    appointmentCalendarStore.setMonthlyCalendarViewMode(viewMode);
  },
});

const handleWeekClick = (week: number) => {
  emit("weekSelected", week !== undefined);
  if (week !== undefined) {
    setWeek(week);
  }
};

const handleDateChange = (date: string) => {
  appointmentCalendarStore.setDate(moment(date).format("YYYY-MM-DD"));
};

const dateHeader = computed(() => {
  if (appointmentCalendarStore.calendarType === CalendarType.SCHEDULE) {
    const momentInstance = moment(appointmentCalendarStore.date).locale(
      "zh_tw"
    );
    return momentInstance.format("dd");
  } else if (appointmentCalendarStore.calendarType === CalendarType.CATEGORY) {
    const momentInstance = moment(appointmentCalendarStore.date).locale(
      "zh_tw"
    );
    let label = momentInstance.format("dd");
    if (dueDate?.value) {
      label = `${label} (${pregnantWeek(
        appointmentCalendarStore.date,
        dueDate.value
      )})`;
    }
    return label;
  } else if (appointmentCalendarStore.calendarType === CalendarType.MONTH) {
    return (
      appointmentCalendarStore.date?.split("-").slice(0, 2).join("/") || ""
    );
  } else if (
    appointmentCalendarStore.calendarType === CalendarType.CUSTOM_WEEK
  ) {
    if (appointmentCalendarStore.dueDate) {
      const days =
        280 -
        moment(appointmentCalendarStore.dueDate).diff(
          appointmentCalendarStore.date,
          "days"
        );
      return `${Math.floor(days / 7)}+${days % 7}`;
    }
    return (
      appointmentCalendarStore.date?.split("-").slice(0, 2).join("/") || ""
    );
  }
  return "";
});

function handleTodayClick() {
  appointmentCalendarStore.setCalendarType(CalendarType.CATEGORY);
  appointmentCalendarStore.setDate(
    moment().tz("Asia/Taipei").format("YYYY-MM-DD")
  );
}

function handlePrevClick() {
  emit("prevClicked");
}

function handleNextClick() {
  emit("nextClicked");
}

function setWeek(week: number) {
  if (dueDate?.value) {
    const dueDateInstance = moment(dueDate.value);
    dueDateInstance.subtract(40 - week, "weeks");
    switch (week) {
      case 22:
        dueDateInstance.subtract(3, "days");
        break;
    }
    appointmentCalendarStore.setDateRange(dueDateInstance.format("YYYY-MM-DD"));
  }
}

const handleDueDateChanged = (dueDate: string) => {
  appointmentCalendarStore.setDueDate(dueDate);
  showDueDatePicker.value = false;
};

watch(
  tenant,
  () => {
    if (route.query.appointmentId) {
      return;
    }
    const queryDate = route.query.date;
    if (queryDate) {
      useAppointmentCalendarStore().setDate(queryDate as string);
    } else if (!appointmentCalendarStore.date) {
      // @ts-ignore
      const tzId = tenant.value.tzId;
      if (tzId) {
        const date = format(new TZDate(new Date(), tzId), "yyyy-MM-dd");
        useAppointmentCalendarStore().setDate(date);
      }
    }
  },
  { immediate: true }
);

onMounted(() => {
  doctorStore.fetchDoctors();
  appointmentStore.fetchAppointmentMetadata();
  if (!props.supportedTypes.includes(appointmentCalendarStore.calendarType)) {
    appointmentCalendarStore.setCalendarType(props.supportedTypes[0]);
  }
});

const openOrderTablePage = () => {
  const { doctors: doctorRecord } = useDoctorStore();
  const { timeSlotsForCurrentDate, date: currentDate } =
    useAppointmentCalendarStore();
  const { appointments: appointmentsRecord } = useAppointmentStore();
  const timeSlots = timeSlotsForCurrentDate;
  const doctors = Object.values(doctorRecord);
  doctors.sort((a, b) => a.order - b.order);
  // Group timeSlots by doctorId
  const doctorIdToTimeSlots: Record<string, { [time: string]: TimeSlot[] }> =
    {};

  for (const timeSlot of timeSlots) {
    if (!doctorIdToTimeSlots[timeSlot.doctorId]) {
      doctorIdToTimeSlots[timeSlot.doctorId] = {};
    }
    const timeString = timeSlot.start.slice(11, 16); // 2023-01-01T07:30:00
    if (!timeString) continue;
    if (!doctorIdToTimeSlots[timeSlot.doctorId][timeString]) {
      doctorIdToTimeSlots[timeSlot.doctorId][timeString] = [];
    }
    doctorIdToTimeSlots[timeSlot.doctorId][timeString].push(timeSlot);
  }
  const rows: OrderTableRow[] = [];
  const times = [
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
  ];
  // Sort timeSlots by start time
  for (const doctor of doctors) {
    const row: OrderTableRow = { key: doctor._id, cells: [] };
    const timeSlotsRecord = doctorIdToTimeSlots[doctor._id];
    if (!timeSlotsRecord) continue;
    for (const time of times) {
      const timeSlots = timeSlotsRecord[time];
      if (!timeSlots) continue;
      for (const timeSlot of timeSlots) {
        const appointments = timeSlot?.appointmentIds
          ?.map((id) => appointmentsRecord[id])
          .filter((appt): appt is Appointment => !!appt);
        if (!appointments) continue;
        for (const appointment of appointments) {
          row.cells.push({
            key: `cell_${doctor._id}_${appointment.chineseName}_${time}`,
            backgroundColor: doctor.color,
            textList: [
              `${generateOrderTableLabelPrefix(appointment)}${
                appointment.chineseName
              }${generateOrderTableLabelSuffix(appointment)}`,
              time,
            ],
          });
        }
      }
    }
    if (row.cells.length) {
      rows.push(row);
    }
  }

  // return rows;
  const htmlString = `
    <table>
      ${rows
        .map((row) => {
          return `<tr>
                    <td>
                      ${moment(currentDate).format("M/D")}<br/>
                      (總台數：${row.cells.length})
                    </td>
        ${row.cells
          .map((cell) => {
            return `<td style="background-color: ${cell.backgroundColor}">
                    ${cell.textList
                      .map((text) => `<div>${text}</div>`)
                      .join("")}
                    </td>
                    <td></td>
                    `;
          })
          .join("")}
                </tr>`;
        })
        .join("")}
    </table>
    <style>
      table,
      th,
      td {
        border: 1px solid;
      }
    </style>
    `;
  // open a new tab with the HTML string as the content
  const newTab = window.open();
  if (!newTab) return;
  newTab.document.open();
  newTab.document.write(htmlString);
  newTab.document.title = "排台表（請 Command+A 複製）";
  newTab.document.close();
};

const handleRemarksClick = () => {
  showRemarksDialog.value = true;
};

const handleStaffClick = () => {
  showStaffDialog.value = true;
};
</script>

<style scoped>
.fill-width {
  width: 100%;
}
::v-deep .v-toolbar__content {
  padding: 0px !important;
}

::v-deep .v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: black !important;
}
</style>

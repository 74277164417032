import axios from "axios";

export const axiosPost = async <T>(url: string, body?: unknown): Promise<T> => {
  try {
    const { data }: { data: T } = await axios.post(url, body);
    return data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e) && e.response?.data.message) {
      throw new Error(e.response.data.message);
    }
    throw new Error("系統錯誤！");
  }
};

export const axiosPut = async <T>(url: string, body?: unknown): Promise<T> => {
  try {
    const { data }: { data: T } = await axios.put(url, body);
    return data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e) && e.response?.data.message) {
      throw new Error(e.response.data.message);
    }
    throw new Error("系統錯誤！");
  }
};

import { DayRemarkDTO, DayRemarkEditInput } from "@/types";
import axios from "axios";
import { defineStore } from "pinia";

interface State {
  dayRemarks: Record<string, DayRemarkDTO | undefined>;
  dateLoadedMap: Record<string, boolean>;
}

export const useDayRemarkStore = defineStore("dayRemark", {
  state: (): State => ({
    dayRemarks: {},
    dateLoadedMap: {},
  }),
  actions: {
    async fetchDayRemark(date: string) {
      if (this.dateLoadedMap[date] === false) {
        return;
      }
      if (this.dateLoadedMap[date] === undefined) {
        this.dateLoadedMap = {
          ...this.dateLoadedMap,
          [date]: false,
        };
      }
      const { data }: { data: DayRemarkDTO } = await axios.get(
        `day-remarks?date=${date}`
      );
      this.setDayRemarks([data]);
      if (this.dateLoadedMap[date] === false) {
        this.dateLoadedMap = {
          ...this.dateLoadedMap,
          [date]: true,
        };
      }
    },
    async createOrUpdateDayRemark(
      date: string,
      dayRemark: DayRemarkEditInput
    ): Promise<void> {
      const { data }: { data: DayRemarkDTO } = await axios.post(
        `day-remarks?date=${date}`,
        dayRemark
      );
      this.setDayRemarks([data]);
    },
    setDayRemarks(dayRemarks: DayRemarkDTO[]): void {
      dayRemarks.forEach((dayRemark) => {
        this.dayRemarks = {
          ...this.dayRemarks,
          [dayRemark.date]: dayRemark,
        };
      });
    },
    handleWsUpdate(date: string) {
      if (this.dateLoadedMap[date]) {
        this.fetchDayRemark(date);
      }
    },
  },
  debounce: {
    fetchDayRemark: 300,
  },
});

export enum CalendarType {
  SCHEDULE = "schedule",
  CATEGORY = "category",
  MONTH = "month",
  CUSTOM_WEEK = "custom_week",
}

export enum PatientSelectionMode {
  NEW = "new",
  EXISTING = "existing",
}

export enum TaiwanIdEnum {
  CITIZEN = "citizen",
  FOREIGNER = "foreigner",
}

export enum AlertItemTypeEnum {
  APPOINTMENT_REQUEST = "AppointmentRequest",
  PATIENT = "Patient",
  APPOINTMENT_GROUP = "AppointmentGroup",
  APPOINTMENT = "Appointment",
  DAY_CALENDAR = "DayCalendar",
  MONTH_CALENDAR = "MonthCalendar",
}

export enum AlertActionEnum {
  UPDATE = "update",
  CREATE = "create",
  VIEW = "view",
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-toolbar',{staticClass:"mb-2",attrs:{"flat":"","dense":""}},[(_vm.supportedTypes.length > 1)?_c('v-btn-toggle',{staticClass:"mr-2",attrs:{"dense":""},model:{value:(_setup.calendarType),callback:function ($$v) {_setup.calendarType=$$v},expression:"calendarType"}},_vm._l((_vm.supportedTypes),function(supportedType){return _c('v-btn',{key:supportedType,staticClass:"white",attrs:{"value":supportedType}},[_vm._v(" "+_vm._s(_setup.LABELS[supportedType])+" ")])}),1):_vm._e(),_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","height":"36"},on:{"click":_setup.handleTodayClick}},[_vm._v(" "+_vm._s(_setup.LABELS.today)+" ")]),(_setup.appointmentCalendarStore.calendarType === _setup.CalendarType.CATEGORY)?[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","height":"36"},on:{"click":_setup.openOrderTablePage}},[_vm._v(" "+_vm._s(_setup.LABELS.orderTable)+" ")]),_c('v-btn',{staticClass:"mr-2",class:{
          red: !!_setup.remark,
          'white--text': !!_setup.remark,
        },attrs:{"outlined":"","height":"36"},on:{"click":_setup.handleRemarksClick}},[_vm._v(" 備註 ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","height":"36"},on:{"click":_setup.handleStaffClick}},[_vm._v(" 人力表 ")])]:_vm._e(),(_setup.appointmentCalendarStore.date)?_c(_setup.DayRemarkDialog,{attrs:{"remark":_setup.remark,"date":_setup.appointmentCalendarStore.date},on:{"close":function($event){_setup.showRemarksDialog = false}},model:{value:(_setup.showRemarksDialog),callback:function ($$v) {_setup.showRemarksDialog=$$v},expression:"showRemarksDialog"}}):_vm._e(),(_setup.appointmentCalendarStore.date)?_c(_setup.StaffDialog,{attrs:{"date":_setup.appointmentCalendarStore.date},on:{"close":function($event){_setup.showStaffDialog = false}},model:{value:(_setup.showStaffDialog),callback:function ($$v) {_setup.showStaffDialog=$$v},expression:"showStaffDialog"}}):_vm._e(),(
        [_setup.CalendarType.MONTH, _setup.CalendarType.CUSTOM_WEEK].includes(
          _setup.appointmentCalendarStore.calendarType
        )
      )?_c('v-btn-toggle',{attrs:{"dense":""},model:{value:(_setup.selectedViewMode),callback:function ($$v) {_setup.selectedViewMode=$$v},expression:"selectedViewMode"}},[_c('v-btn',{attrs:{"value":"show_available","outlined":""}},[_vm._v(" 顯示可約 ")]),_c('v-btn',{attrs:{"value":"show_all","outlined":""}},[_vm._v(" 顯示所有 ")])],1):_vm._e(),_c('v-btn',{attrs:{"fab":"","text":"","medium":"","color":"grey darken-2"},on:{"click":_setup.handlePrevClick}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-chevron-left")])],1),_c('div',{staticClass:"d-flex align-center"},[_c('h3',[_vm._v(_vm._s(_setup.dateHeader))]),(
          [_setup.CalendarType.CATEGORY, _setup.CalendarType.SCHEDULE].includes(
            _setup.appointmentCalendarStore.calendarType
          )
        )?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-2 font-weight-bold",staticStyle:{"width":"100px","font-size":"18px"},attrs:{"value":_setup.appointmentCalendarStore.date,"dense":"","hide-details":"","readonly":""}},'v-text-field',attrs,false),on))]}}],null,false,1471438192),model:{value:(_setup.showCurrentDatePicker),callback:function ($$v) {_setup.showCurrentDatePicker=$$v},expression:"showCurrentDatePicker"}},[(_setup.appointmentCalendarStore.date)?_c('v-date-picker',{attrs:{"value":_setup.appointmentCalendarStore.date},on:{"change":_setup.handleDateChange,"input":function($event){_setup.showCurrentDatePicker = false}}}):_vm._e()],1):_vm._e()],1),_c('v-btn',{attrs:{"fab":"","text":"","medium":"","color":"grey darken-2"},on:{"click":_setup.handleNextClick}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-chevron-right")])],1),_c('v-spacer'),(
        [
          _setup.CalendarType.CATEGORY,
          _setup.CalendarType.MONTH,
          _setup.CalendarType.CUSTOM_WEEK,
        ].includes(_setup.appointmentCalendarStore.calendarType)
      )?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","left":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"min-width":"115px","max-width":"115px"},attrs:{"value":_setup.dueDate,"label":_setup.LABELS.dueDate,"hide-details":"","type":"date","max":"2999-12-31","outlined":"","dense":""},on:{"change":_setup.handleDueDateChanged}},'v-text-field',attrs,false),on))]}}],null,false,3682291845),model:{value:(_setup.showDueDatePicker),callback:function ($$v) {_setup.showDueDatePicker=$$v},expression:"showDueDatePicker"}},[_c('v-date-picker',{on:{"input":function($event){_setup.showDueDatePicker = false}},model:{value:(_setup.dueDate),callback:function ($$v) {_setup.dueDate=$$v},expression:"dueDate"}})],1):_vm._e(),(_setup.calendarType !== _setup.CalendarType.SCHEDULE && _setup.dueDate)?_c('span',{staticClass:"ml-2"},_vm._l((_setup.availableWeeks),function(week){return _c('v-btn',{key:week,staticClass:"mr-1",attrs:{"outlined":"","height":"39","small":""},on:{"click":function($event){return _setup.handleWeekClick(week)}}},[_vm._v(" "+_vm._s(week)+" ")])}),1):_vm._e()],2),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <Calendar
    :supported-types="SUPPORTED_CALENDAR_TYPES"
    @prevClicked="handleDateSwitch(-1)"
    @nextClicked="handleDateSwitch(1)"
    @weekSelected="setIsWeekSelected"
  >
    <div class="scrollable-div">
      <AppointmentCalendarMonth
        v-if="appointmentCalendarStore.isMonthCalendarView"
      />
      <AppointmentCalendarCustomWeek
        v-else-if="appointmentCalendarStore.isCustomWeekView"
      />
      <AppointmentCalendarCategory
        v-else-if="appointmentCalendarStore.isCategoryView"
      />
    </div>
  </Calendar>
</template>

<script setup lang="ts">
import AppointmentCalendarMonth from "@/components/appointmentCalendarMonth/appointmentCalendarMonth.vue";
import AppointmentCalendarCategory from "@/components/appointmentCalendarCategory/appointmentCalendarCategory.vue";
import { CalendarType } from "@/shared/sharedEnums";
import Calendar from "@/components/calendar/calendar.vue";
import { useAppointmentCalendarStore } from "@/stores/appointmentCalendar";
import AppointmentCalendarCustomWeek from "../appointmentCalendarCustomWeek/appointmentCalendarCustomWeek.vue";
import { Duration } from "date-fns";

const SUPPORTED_CALENDAR_TYPES = [CalendarType.CATEGORY, CalendarType.MONTH];
const appointmentCalendarStore = useAppointmentCalendarStore();

const setIsWeekSelected = (isSelected: boolean) => {
  if (isSelected) {
    appointmentCalendarStore.setCalendarType(CalendarType.CUSTOM_WEEK);
  } else {
    appointmentCalendarStore.setCalendarType(CalendarType.MONTH);
  }
};

const handleDateSwitch = (incrementVal: number): void => {
  const duration: Duration =
    appointmentCalendarStore.calendarType === CalendarType.CATEGORY
      ? { days: incrementVal }
      : appointmentCalendarStore.isCustomWeekView
      ? { weeks: incrementVal }
      : { months: incrementVal };
  appointmentCalendarStore.incrementDate(duration);
};
</script>

<style>
.scrollable-div {
  overflow-x: hidden; /* Disable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  height: 85vh;
}
</style>

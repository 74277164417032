<template>
  <v-calendar
    :value="appointmentCalendarStore.date"
    weekdays="1,2,3,4,5,6"
    type="month"
    @change="handleCalendarDateChange"
    @click:date="handleDateClick"
  >
    <template #day-label="{ date }">
      <span class="text-subtitle-1">{{ moment(date).format("M/D") }}</span>
      <span class="text-subtitle-2 text--grey grey--text">
        {{
          pregnantWeek(date, dueDate) ? `(${pregnantWeek(date, dueDate)})` : ""
        }}</span
      >
    </template>
    <template #day="{ date }">
      <AppointmentCalendarAvailability :date="date" />
    </template>
  </v-calendar>
</template>

<script setup lang="ts">
import { CalendarType } from "@/shared/sharedEnums";
import { pregnantWeek } from "@/utils/utils";
import { useAppointmentCalendarStore } from "@/stores/appointmentCalendar";
import { storeToRefs } from "pinia";
import AppointmentCalendarAvailability from "../appointmentCalendarAvailability/appointmentCalendarAvailability.vue";
import moment from "moment";

const appointmentCalendarStore = useAppointmentCalendarStore();
const { dueDate } = storeToRefs(appointmentCalendarStore);

const handleDateClick = (event: { date: string }): void => {
  appointmentCalendarStore.setCalendarType(CalendarType.CATEGORY);
  appointmentCalendarStore.setDate(event.date);
};

const handleCalendarDateChange = (date: { start: { date: string } }) => {
  appointmentCalendarStore.setDate(date.start.date);
};
</script>

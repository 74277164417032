var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-center"},[(_setup.isLoadingTimeSlots)?_c('v-progress-circular',{staticClass:"center-vertical",attrs:{"indeterminate":"","color":"primary"}}):(_setup.sites.length > 0)?_c('table',{staticClass:"appt-table",staticStyle:{"width":"100%"},attrs:{"border":"1"}},[_c('tr',{staticStyle:{"position":"sticky","top":"0","background-color":"white","z-index":"1"}},[_c('th',{staticClass:"time-col"}),_vm._l((_setup.sites),function(site){return _c('th',{key:site,attrs:{"colspan":_setup.siteColumnCounts.get(site) || 0}},[_vm._v(" "+_vm._s(_setup.SHARED_LABELS.clinicSite[site])+" ")])})],2),_vm._l((_setup.times),function(time){return _c('tr',{key:time},[_c('td',{staticClass:"time-col"},[_vm._v(_vm._s(time))]),_vm._l((_setup.sites),function(site){return [_vm._l((_setup.doctorIdsBySite.get(site)),function(doctorId){return _vm._l((_setup.getEvents(site, doctorId, time)),function(calendarEvent){return _c('td',{key:calendarEvent.calendarEventId,class:{
              actionable: calendarEvent.isActionable,
              focused: calendarEvent.focused,
            },style:(`background-color: ${calendarEvent.backgroundColor}`),on:{"click":function($event){return _setup.handleCalendarEventClick(calendarEvent)}}},[(calendarEvent.label)?_c('div',{staticClass:"calendar-event d-flex flex-column justify-center",class:{
                'pending-response': calendarEvent.isPendingResponse,
                'request-to-cancel': calendarEvent.isRequestingToCancel,
              }},[_c('div',{staticClass:"patient-name",attrs:{"title":calendarEvent.remarks}},[(calendarEvent.showStar)?_c('span',[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(calendarEvent.label)+" "),(calendarEvent.isVirtual)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_setup.LOCATION_TYPE_TO_ICON.virtual)+" ")]):_vm._e()],1),_c('div',[_vm._v(_vm._s(calendarEvent.weekLabel))]),(calendarEvent.focused)?_c('div',{staticClass:"selected-appointment-box"}):_vm._e()]):(calendarEvent.isChecked)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()],1)})})]})],2)})],2):_c('div',{staticClass:"d-flex justify-center text-center flex-column",staticStyle:{"height":"500px"}},[_c('v-icon',{attrs:{"size":200}},[_vm._v(" mdi-alert-circle-outline ")]),_c('div',[_vm._v("尚未開放門診表！")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseApp = initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
});
const auth = getAuth(firebaseApp);
const messaging = getMessaging(firebaseApp);

const getFCMToken = async () => {
  const fcmToken = await getToken(messaging, {
    vapidKey:
      "BCzxZATQbitYtUWQVl6yGii1r5eOy7RV5L5LujqgCqs1AmpDbDkCBcvdaHGEbUgrLE0Htao8bO-RghSCOmxfGaY",
  });
  return fcmToken;
};

export { auth, messaging, getFCMToken };

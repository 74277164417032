import { getPregnancyWeekNumber } from "@/shared/appointmentHelpers";
import { Appointment } from "@/shared/sharedTypes";

export const generateOrderTableLabelSuffix = (appointment: Appointment) => {
  let suffix = "";
  if (
    appointment.appointmentGroupCategory === "fetal" &&
    appointment.fetusCount &&
    typeof appointment.fetusCount === "number" &&
    appointment.fetusCount > 1
  ) {
    suffix += `[${appointment.fetusCount}]`;
  }
  if (
    appointment.appointmentGroupType === "follow_up" ||
    appointment.appointmentGroupType === "new_born_follow_up"
  ) {
    suffix += "[F]";
  } else {
    // Check checkTypes
    const followUpCheckType = appointment.checkTypes.find(
      (checkType) => checkType === "follow_up"
    );
    if (followUpCheckType) {
      suffix += "[F]";
    }
  }

  return suffix;
};

export const generateOrderTableLabelPrefix = (appointment: Appointment) => {
  if (appointment.appointmentGroupCategory === "fetal") {
    if (appointment.dueDate) {
      const { weeks } = getPregnancyWeekNumber(
        appointment.date,
        appointment.dueDate
      );
      if (weeks) {
        // NT 11+0-13+6
        if (weeks >= 11 && weeks < 14) {
          return "N";
        }
        // 早期:14+0-15+6
        if (weeks >= 14 && weeks < 16) {
          return "E";
        }
        // Plus:16+0-19+6
        if (weeks >= 16 && weeks < 20) {
          return "P";
        }
      }
    }
  }
  return "";
};

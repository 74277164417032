<template>
  <div class="d-flex flex-column" @click="focusOnDate(date)">
    <div class="day-wrapper">
      <ul class="pl-0" style="list-style-type: none">
        <template v-for="site in sites">
          <li
            v-if="
              !isDoctorUnscheduled(date, site.value) &&
              getDoctorsAndAvailability(
                date,
                site.value,
                monthlyCalendarViewMode === 'show_all'
              )?.length
            "
            :key="site.value"
            class="d-flex mb-1"
          >
            <span class="text-subtitle-2 mr-1">{{ site.label }}</span>
            <template
              v-for="doctorsAndAvailability in getDoctorsAndAvailability(
                date,
                site.value,
                monthlyCalendarViewMode === 'show_all'
              )"
            >
              <span
                v-if="doctorsAndAvailability.availability !== 'unavailable'"
                :key="doctorsAndAvailability.doctor._id"
                :class="`doctor-availability-circle d-flex flex-column mr-1`"
                :title="`${doctorsAndAvailability.doctor.name} (AM: ${doctorsAndAvailability.availabilityCount.am} PM: ${doctorsAndAvailability.availabilityCount.pm})`"
              >
                <span
                  class="half-circle top"
                  :style="`background-color: ${doctorsAndAvailability.color.top};`"
                ></span>
                <span
                  class="half-circle bottom"
                  :style="`background-color: ${doctorsAndAvailability.color.bottom};`"
                ></span>
              </span>
            </template>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDoctorAvailability } from "@/composables/useDoctorAvailability";
import { CalendarType } from "@/shared/sharedEnums";
import { useAppointmentCalendarStore } from "@/stores/appointmentCalendar";
import { ClinicSite } from "@/types";
import { storeToRefs } from "pinia";

type PropType = {
  date: string;
};

defineProps<PropType>();

const { getDoctorsAndAvailability, isDoctorUnscheduled } =
  useDoctorAvailability();
const appointmentCalendarStore = useAppointmentCalendarStore();
const { monthlyCalendarViewMode } = storeToRefs(appointmentCalendarStore);

const sites: { label: string; value: ClinicSite }[] = [
  { label: "總", value: "new_taipei" },
  { label: "台", value: "taipei" },
  { label: "竹", value: "chupei" },
  { label: "苗", value: "miaoli" },
  { label: "員", value: "yuanlin" },
];

const focusOnDate = (date: string): void => {
  appointmentCalendarStore.setCalendarType(CalendarType.CATEGORY);
  appointmentCalendarStore.setDate(date);
};
</script>

<style lang="scss" scoped>
$circle-size: 22px;

.day-wrapper {
  min-height: 100px;
  display: flex;
  align-items: start;
  justify-content: center;
}

.doctor-availability-circle {
  cursor: pointer;
  height: $circle-size;
}

.half-circle {
  height: $circle-size / 2;
  width: $circle-size;
  &.top {
    border-top-left-radius: $circle-size * 2;
    border-top-right-radius: $circle-size * 2;
  }

  &.bottom {
    border-bottom-left-radius: $circle-size * 2;
    border-bottom-right-radius: $circle-size * 2;
  }
}
</style>

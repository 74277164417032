var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column",on:{"click":function($event){return _setup.focusOnDate(_vm.date)}}},[_c('div',{staticClass:"day-wrapper"},[_c('ul',{staticClass:"pl-0",staticStyle:{"list-style-type":"none"}},[_vm._l((_setup.sites),function(site){return [(
            !_setup.isDoctorUnscheduled(_vm.date, site.value) &&
            _setup.getDoctorsAndAvailability(
              _vm.date,
              site.value,
              _setup.monthlyCalendarViewMode === 'show_all'
            )?.length
          )?_c('li',{key:site.value,staticClass:"d-flex mb-1"},[_c('span',{staticClass:"text-subtitle-2 mr-1"},[_vm._v(_vm._s(site.label))]),_vm._l((_setup.getDoctorsAndAvailability(
              _vm.date,
              site.value,
              _setup.monthlyCalendarViewMode === 'show_all'
            )),function(doctorsAndAvailability){return [(doctorsAndAvailability.availability !== 'unavailable')?_c('span',{key:doctorsAndAvailability.doctor._id,class:`doctor-availability-circle d-flex flex-column mr-1`,attrs:{"title":`${doctorsAndAvailability.doctor.name} (AM: ${doctorsAndAvailability.availabilityCount.am} PM: ${doctorsAndAvailability.availabilityCount.pm})`}},[_c('span',{staticClass:"half-circle top",style:(`background-color: ${doctorsAndAvailability.color.top};`)}),_c('span',{staticClass:"half-circle bottom",style:(`background-color: ${doctorsAndAvailability.color.bottom};`)})]):_vm._e()]})],2):_vm._e()]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Appointment from "../views/appointment/appointment.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/login.vue"),
    meta: {
      bypassAuth: true,
    },
  },
  {
    path: "/confirm",
    name: "confirm",
    component: () =>
      import(/* webpackChunkName: "confirm" */ "../views/confirm/confirm.vue"),
    meta: {
      bypassAuth: true,
    },
  },
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "authed" */ "../layouts/Authed/Authed.vue"),
    children: [
      {
        path: "/appointment-groups/:appointmentGroupId/forms",
        component: () =>
          import(/* webpackChunkName: "forms" */ "../views/forms/forms.vue"),
      },
      {
        path: "/receipts/:receiptId",
        component: () =>
          import(
            /* webpackChunkName: "receipt" */ "../views/receipt/receipt.vue"
          ),
      },
      {
        path: "/",
        component: () =>
          import(
            /* webpackChunkName: "default" */ "../layouts/Default/Default.vue"
          ),
        children: [
          {
            path: "",
            redirect: { name: "appointment" },
          },
          {
            path: "/appointment-group/*",
            redirect: { name: "appointment" },
          },
          {
            path: "/schedule",
            name: "schedule",
            component: () =>
              import(
                /* webpackChunkName: "schedule" */ "../views/schedule/schedule.vue"
              ),
          },
          {
            path: "/appointment",
            name: "appointment",
            component: Appointment,
          },
          {
            path: "/refer",
            name: "refer",
            component: () =>
              import(
                /* webpackChunkName: "refer" */ "../views/refer/refer.vue"
              ),
          },
          {
            path: "/appointment-groups",
            name: "appointmentGroups",
            component: () =>
              import(
                /* webpackChunkName: "appointmentGroups" */ "../views/appointmentGroups/appointmentGroups.vue"
              ),
          },
          {
            path: "/appointments",
            name: "appointments",
            component: () =>
              import(
                /* webpackChunkName: "appointments" */ "../views/appointments/appointments.vue"
              ),
          },
          {
            path: "/transactions",
            name: "transactions",
            component: () =>
              import(
                /* webpackChunkName: "transactions" */ "../views/transactions/transactions.vue"
              ),
          },
          {
            path: "/receipts",
            name: "receipts",
            component: () =>
              import(
                /* webpackChunkName: "receipts" */ "../views/receipts/receipts.vue"
              ),
          },
          {
            path: "/bank-transactions",
            name: "bankTransactions",
            component: () =>
              import(
                /* webpackChunkName: "bankTransactions" */ "../views/bankTransactions/bankTransactions.vue"
              ),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

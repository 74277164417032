import { useDoctorStore } from "@/stores/doctor";
import { useOverviewStore } from "@/stores/overview";
import { ClinicSite, Doctor } from "@/types";

export type DoctorAvailability =
  | "am_available"
  | "pm_available"
  | "am_pm_available"
  | "unavailable"
  | "unscheduled";

export type DoctorWithAvailability = {
  doctor: Doctor;
  availabilityCount: { am: number; pm: number };
  availability: DoctorAvailability;
  color: { top: string; bottom: string };
};

export function useDoctorAvailability() {
  const overviewStore = useOverviewStore();
  const doctorStore = useDoctorStore();

  const getDoctorsAndAvailability = (
    date: string,
    site: ClinicSite,
    showAll?: boolean
  ) => {
    const overviewsAtDate = overviewStore.getOverviewsByDate(date);
    let amOpenings = 0;
    let pmOpenings = 0;
    if (overviewsAtDate !== null && overviewsAtDate.length !== 0) {
      const doctorWithAvailability: Array<DoctorWithAvailability> = [];
      const doctors = Object.values(doctorStore.doctors);
      if (doctors !== null && doctors.length !== 0) {
        overviewsAtDate.forEach((overview) => {
          if (overview.site === site) {
            const doctorIdsInMetadata = Object.keys(overview.metadata);
            let doctorAvailability: DoctorAvailability;
            doctors.forEach((doctor) => {
              if (doctorIdsInMetadata.includes(doctor._id)) {
                const metadata = overview.metadata[doctor._id];
                if (metadata) {
                  if (showAll) {
                    amOpenings = metadata.am?.total ?? 0;
                    pmOpenings = metadata.pm?.total ?? 0;
                  } else {
                    metadata.am && metadata.am.total > metadata.am.booked
                      ? (amOpenings = metadata.am.total - metadata.am.booked)
                      : (amOpenings = 0);
                    metadata.pm && metadata.pm.total > metadata.pm.booked
                      ? (pmOpenings = metadata.pm.total - metadata.pm.booked)
                      : (pmOpenings = 0);
                  }
                }
                if (amOpenings && pmOpenings) {
                  doctorAvailability = "am_pm_available";
                } else if (amOpenings) {
                  doctorAvailability = "am_available";
                } else if (pmOpenings) {
                  doctorAvailability = "pm_available";
                } else {
                  doctorAvailability = "unavailable";
                }
                doctorWithAvailability.push({
                  doctor,
                  availabilityCount: {
                    am: amOpenings,
                    pm: pmOpenings,
                  },
                  availability: doctorAvailability,
                  color: getAvailabilityCircleColor(
                    doctorAvailability,
                    doctor.color
                  ),
                });
              }
            });
          }
        });
        return doctorWithAvailability;
      }
    }
  };

  const getAvailabilityCircleClass = (
    availability: DoctorAvailability
  ): string => {
    switch (availability) {
      case "am_available":
        return "top";
      case "pm_available":
        return "bottom";
      case "am_pm_available":
        return "top bottom";
      default:
        return "";
    }
  };

  const getAvailabilityCircleColor = (
    availability: DoctorAvailability,
    colorHex: string
  ): { top: string; bottom: string } => {
    switch (availability) {
      case "am_available":
        return {
          top: colorHex,
          bottom: "transparent",
        };
      case "pm_available":
        return {
          top: "transparent",
          bottom: colorHex,
        };
      case "am_pm_available":
        return {
          top: colorHex,
          bottom: colorHex,
        };
      default:
        return {
          top: "transparent",
          bottom: "transparent",
        };
    }
  };

  const isDoctorUnscheduled = (date: string, site: ClinicSite): boolean => {
    const doctorsAndAvailability = getDoctorsAndAvailability(date, site);
    if (doctorsAndAvailability && doctorsAndAvailability.length === 0) {
      return true;
    }
    return false;
  };

  return {
    getDoctorsAndAvailability,
    isDoctorUnscheduled,
    getAvailabilityCircleClass,
  };
}
